/*-----------------------------------------------
|   Typed Text
-----------------------------------------------*/
.typed-cursor {
  background: currentColor;
  width: 0.125rem !important;
  display: inline-block;
  text-indent: 10000rem;
  line-height: 1.4em
}
