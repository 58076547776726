/*-----------------------------------------------
|   Height
-----------------------------------------------*/
@each $breakpoint in map_keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
    .min-vh#{$infix}-100{ min-height: 100vh; }
    .min-vh#{$infix}-75{ min-height: 75vh; }
    .min-vh#{$infix}-50{ min-height: 50vh; }
    .min-vh#{$infix}-25{ min-height: 25vh; }
    .max-vh#{$infix}-100{ max-height: 100vh; }
    .max-vh#{$infix}-75{ max-height: 75vh; }
    .max-vh#{$infix}-50{ max-height: 50vh; }
    .max-vh#{$infix}-25{ max-height: 25vh; }
    .max-vh#{$infix}-100{ max-height: 100vh; }
    .max-w#{$infix}-75{ max-width: 75% !important; }
    .max-w#{$infix}-50{ max-width: 50% !important; }
    .max-w#{$infix}-25{ max-width: 25% !important; }
    .vh#{$infix}-100{ height: 100vh; }
    .vh#{$infix}-75{ height: 75vh; }
    .vh#{$infix}-50{ height: 50vh; }
    .vh#{$infix}-25{ height: 25vh; }
  }
}

// Used in chat 
.min-w-0{ min-width: 0; }

// Used in canvas
.max-w-100{ max-width: 100%; }
