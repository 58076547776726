/*-----------------------------------------------
|   Perfect Scrollbar
-----------------------------------------------*/
.ie,
.edge {
  .perfect-scrollbar {
    position: relative;
    overflow-y: auto !important;
  }
  .ps__rail-y {
    width: 5px !important;
    background-color: transparent !important;
    .ps__thumb-y {
      width: 5px;
      background-color: rgba($gray-600, 0.5) !important;
    }
    @include hover-focus {
      background-color: transparent !important;
      .ps__thumb-y {
        width: 5px !important;
      }
    }
  }
}
