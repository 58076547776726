/*-----------------------------------------------
|   Fancybox
-----------------------------------------------*/
.fancybox-caption-wrap, .fancybox-infobar{
  display: none;
}
.fancybox-toolbar{
  top: 1rem;
  right: 1rem;
  opacity: 1;
  visibility: visible !important;
}
