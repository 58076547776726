.select2-container--bootstrap4 {
  .select2-selection--multiple {

    // height of input box
    min-height: $input-height !important;

    // input box
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 8px;
      width: 100%;

      .select2-search__field {
        width: 100% !important;
      }
    }

    // each selected item in input box
    .select2-selection__choice {
      color: $dark;
      border: 1px solid darken($light, 20%);
      @include border-radius($input-border-radius-sm);
      padding: 0;
      padding-right: 5px;
      cursor: pointer;
      float: left;
      margin-top: 0.3em;
      margin-right: 5px;
    }

    // x button of each selected item in input box
    .select2-selection__choice__remove {
      color: darken($light, 20%);
      font-weight: bold;
      margin-left: 3px;
      margin-right: 1px;
      padding-right: 3px;
      padding-left: 3px;
      float: left;

      &:hover {
        color: $dark;
      }
    }

    // all clear button
    .select2-selection__clear {
      // overwrite layout.scss
      float: none;
      margin-right: 0;

      position: absolute !important;
      top: 0;
      right: 0.7em;
    }
  }
}
